.quote {
    font-family: 'Rouge Script', cursive;
}


@media screen and (min-width: 601px) {
    .quote {
      font-size: 50px;
    }
  }
  
  /* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
  @media screen and (max-width: 600px) {
    .quote {
      font-size: 30px;
    }
  }