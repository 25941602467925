input {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

.submit {
    background-color: #9ba5f0;
    color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

form {
    background: none;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    max-width: 300px;
    padding: 30px 50px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }