* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;

    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

body {
    background: var(--color-bg);
    color: white;
}

a {
    color: unset;
    text-decoration: none;
}

img {
    display: block;
    max-width: 100%;
    max-height: 100%;
}

#page-wrapper {
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#page-content {
    flex-grow: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mb {
    margin-bottom: 16px;
}

.mb--light {
    margin-bottom: 8px;
}


.mb--heavy {
    margin-bottom: 32px;
}