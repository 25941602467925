@import url('https://fonts.googleapis.com/css2?family=Rouge+Script&display=swap');

#homepage-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 60%;

    user-select: none;
    pointer-events: none;

    z-index: -1;
}

h1 {
    font-family: 'Rouge Script', cursive;
    font-size: 100px !important;
    font-weight: 200;
}

p {
    font-family: 'Rouge Script', cursive;
    font-size: 10px;
}

.content {
    padding: 32px;
    flex-grow: 1;
}

.content.--centered {
    display: flex;
    align-items: center;
}

.profile-picture {
    margin-bottom: 24px;
    max-width: 80%;

    margin-left: auto;
    margin-right: auto;
}

.coming-soon {
    font-size: 32px;
    line-height: 38px;
}

.centered {
    text-align: center;
}

.links {
    color: #0bb4aa;
}